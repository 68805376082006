<template>
  <div>
    <!-- Section principale contenant la description et la table des matières -->
    <section class="main-section">
      <!-- Partie de gauche avec le texte introductif -->
      <div class="left-content">
        <section class="hero ma-section">
          <h1>{{ title }}</h1>
          <p>{{ description1 }}</p>
          <p>{{ description2 }}</p>
        </section>
      </div>
      <!-- Partie de droite avec la table des matières -->
      <div class="solution-voip-toc">
        <h2>Table des matières</h2>
        <ul>
          <li><a href="#cloud-recourse" @click.prevent="smoothScroll('#cloud-recourse')">Pourquoi avoir recours au Cloud ?</a></li>
          <li><a href="#cloud-virtualization" @click.prevent="smoothScroll('#cloud-virtualization')">Découvrez les possibilités de la virtualisation</a></li>
        </ul>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'CloudHeroComponent',
  props: {
    title: {
      type: String,
      default: 'Le Cloud',
    },
    description1: {
      type: String,
      default: "Intégré au paysage entrepreneurial depuis quelques années, le Cloud propose une vaste gamme de services et ouvre aux entreprises un éventail infini de possibilités."
    },
    description2: {
      type: String,
      default: "Dans ce contexte, ALESIA permet à ses clients de saisir ces opportunités tout en hébergeant leurs données dans un datacenter situé en Belgique."
    }
  },
  methods: {
    smoothScroll(target) {
      const element = document.querySelector(target);
      if (!element) {
        console.warn('Element not found:', target);
        return;
      }
      window.scrollTo({
        top: element.offsetTop,
        behavior: 'smooth'
      });
    }
  }
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');

.main-section {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  align-items: stretch;
  padding: 2rem 2rem 0;
  background: linear-gradient(180deg, #cde8ff 0%, #a5d3ff 100%);
}

.left-content {
  flex: 2;
  position: relative;
}

.solution-voip-toc {
  flex: 1;
  background-color: #f0f0f0;
  padding: 2rem;
  border-radius: 8px;
  position: sticky;
  top: calc(var(--navbar-height, 80px) + 20px);
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  width: auto;
  height: auto;
  align-self: center;
}

.solution-voip-toc h2 {
  font-size: 1.5rem;
  margin-bottom: 2rem;
  color: #1e90ff;
}

.solution-voip-toc ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2.5rem;
}

.solution-voip-toc a {
  color: #1e90ff;
  text-decoration: none;
  padding: 0.75rem 1.5rem;
  border: 1px solid #1e90ff;
  border-radius: 5px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.solution-voip-toc a:hover {
  background-color: #1e90ff;
  color: #ffffff;
}

.hero {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  font-family: 'Poppins', sans-serif;
  position: relative;
  z-index: 1;
}

.hero h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  z-index: 2;
  color: #000;
}

.ma-section {
  padding-bottom: 40px;
  z-index: 2;
}

.ma-section p {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  text-align: justify;
  line-height: 1.8;
  z-index: 2;
  color: #000;
}

/* Responsive Styles */
@media (max-width: 1024px) {
  .main-section {
    flex-direction: column;
    padding: 1.5rem;
  }

  .solution-voip-toc {
    position: relative;
    top: 0;
    margin-top: 2rem;
    width: 100%;
  }
}

@media (max-width: 768px) {
  .hero h1 {
    font-size: 2rem;
  }

  .solution-voip-toc h2 {
    font-size: 1.25rem;
  }

  .solution-voip-toc a {
    font-size: 0.875rem;
    padding: 0.5rem 1rem;
  }
}

@media (max-width: 480px) {
  .hero {
    padding: 1rem;
  }

  .hero h1 {
    font-size: 1.75rem;
  }

  .ma-section p {
    font-size: 0.875rem;
  }

  .solution-voip-toc {
    padding: 1.5rem;
    gap: 1.5rem;
  }

  .solution-voip-toc a {
    padding: 0.5rem;
  }
}
</style>