<template>
    <div class="wave-container">
      <svg viewBox="0 0 1600 200" xmlns="http://www.w3.org/2000/svg">
        <path
          fill="#4f53f3"
          opacity="1"
          d="M0,18C534,75,1068,59,1602,166,C1600,150,1600,150,1600,150C1600,150,1600,150,1600,150C1600,150,1600,150,1600,150C1600,150,1600,150,1600,150L1600,150C1066,150,532,150,-2,150,C0,150,0,150,0,150C0,150,0,150,0,150C0,150,0,150,0,150C0,150,0,150,0,150L1398,150L0,150Z"
        />
        <path
          fill="#3a339b"
          opacity="1"
          d="M0,74C534,178,1068,146,1602,104,C1600,150,1600,150,1600,150C1600,150,1600,150,1600,150C1600,150,1600,150,1600,150C1600,150,1600,150,1600,150C1600,150,1600,150,1600,150L1600,150C1066,150,532,150,-2,150,C0,150,0,150,0,150C0,150,0,150,0,150C0,150,0,150,0,150C0,150,0,150,0,150C0,150,0,150,0,150L1398,150L0,150Z"
        />
        <path
          fill="#333aab"
          opacity="1"
          d="M0,221C534,87,1068,214,1602,178,C1600,150,1600,150,1600,150C1600,150,1600,150,1600,150C1600,150,1600,150,1600,150C1600,150,1600,150,1600,150C1600,150,1600,150,1600,150L1600,150C1066,150,532,150,-2,150,C0,150,0,150,0,150C0,150,0,150,0,150C0,150,0,150,0,150C0,150,0,150,0,150C0,150,0,150,0,150L1398,150L0,150Z"
        />
      </svg>
    </div>
  </template>
  
  <script>
  export default {
    name: 'Wave2Component',
  };
  </script>
  
  <style scoped>
  .wave-container {
    margin: 0; /* Réduire la marge autour du conteneur */
    padding: 0; /* Réduire le remplissage si nécessaire */
  }
  </style>
  