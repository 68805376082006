<template>
    <div class="condition-general">
      <h1>Conditions Générales</h1>
      <h2>Alezia SRL</h2>
      <p>Square des Martyrs 1, 6000 Charleroi</p>
      <p>Tel: 071/96.21.82 | Email: info@alezia.be</p>
  
      <h3>Article 1.1. Conditions Générales de Vente et de Livraison</h3>
      <p><strong>1.1.1. Généralité</strong><br>
      L’acceptation des offres, confirmations de commande, bons de livraison et factures d’Alezia implique l’approbation des présentes conditions générales de vente et de livraison, qui remplacent toutes conditions générales de l’acheteur, sauf stipulation contraire indiquée explicitement dans la confirmation de commande. Aucune dérogation à ces conditions ne liera Alezia à moins d'être expressément acceptée par écrit par Alezia.</p>
  
      <p><strong>1.1.2. Modification des Produits et Services</strong><br>
      Alezia se réserve le droit d'apporter des modifications à ses produits et services tels que décrits dans ses prospectus, catalogues, listes de prix, dessins explicatifs, etc., sans obligation de notification préalable.</p>
  
      <h3>Article 1.2. Offre</h3>
      <p><strong>1.2.1. Caractéristiques des Offres</strong><br>
      Toutes les offres faites par Alezia sont, sauf stipulation contraire explicite et par écrit, sans engagement de sa part et hors TVA. Ces offres sont basées sur les informations fournies par l'acheteur au moment de la demande et sont valables pendant une durée d’un mois à compter de la date d’émission.</p>
  
      <h3>Article 1.3. Contrats</h3>
      <p><strong>1.3.1. Spécifications du Fabricant</strong><br>
      Sauf convention écrite contraire, les ventes réalisées par Alezia suivent toujours les spécifications fournies par le fabricant des biens vendus.</p>
  
      <h3>Article 1.4. Installation</h3>
      <p><strong>1.4.1. Définition des Travaux d’Installation</strong><br>
      Les travaux d’installation consistent à mettre en place les biens de manière conforme aux règles de l’art, en tenant compte des possibilités définies par le fabricant et à l'endroit convenu entre Alezia et l’acheteur.</p>
  
      <p><strong>1.4.2. Engagement d'Alezia</strong><br>
      Alezia ne sera tenue d'effectuer les travaux d'installation que si ceux-ci sont expressément mentionnés dans le contrat conclu avec l'acheteur.</p>
  
      <p><strong>1.4.3. Facturation des Travaux d'Installation</strong><br>
      Sauf stipulation contraire explicite et écrite, les travaux d’installation qui doivent être réalisés par Alezia ne sont pas inclus dans le prix d’achat des biens et feront l'objet d'une facturation séparée. Ces travaux seront facturés sur la base d’un tarif horaire.</p>
  
      <p><strong>1.4.4. Compatibilité des Logiciels et Matériels</strong><br>
      Alezia ne peut garantir le bon fonctionnement des logiciels et matériels dans le cas où ceux-ci seraient installés sur des systèmes informatiques qui ne sont pas fournis par elle-même. Il incombe à l’acheteur de s’assurer que le lieu d’installation est adéquat et que les accès nécessaires sont prévus.</p>
  
      <h3>Article 1.5. Formation</h3>
      <p><strong>1.5.1. Objectif de la Formation</strong><br>
      La formation fournie par Alezia couvre l'enseignement et l'instruction nécessaires à l'utilisation correcte des biens vendus. Cette formation doit être donnée à l'acheteur ou aux membres de son personnel désignés pour se servir des biens en question. Alezia se réserve le droit de fixer le contenu, le lieu et la durée de cette formation. En aucun cas Alezia n'assume de responsabilité concernant les résultats de la formation fournie.</p>
  
      <p><strong>1.5.2. Obligation de Formation</strong><br>
      Alezia n’est tenue de dispenser cette formation que si une telle instruction est prévue dans le contrat signé avec l’acheteur.</p>
  
      <h3>Article 1.6. Livraison</h3>
      <p><strong>1.6.1. Objet de la Livraison</strong><br>
      La livraison ne porte que sur les biens et services mentionnés dans la confirmation de commande. Le délai de livraison correspond à celui convenu entre Alezia et l'acheteur, et débute dès la mise en vigueur du contrat. Ce délai s'achève lorsque les biens sont livrés à l'adresse indiquée par l’acheteur. Dans le cas où le contrat inclut également l’installation, la livraison est considérée comme terminée lorsque l’installation est faite.</p>
  
      <p><strong>1.6.2. Respect des Délais</strong><br>
      Les délais de livraison seront respectés dans la mesure du possible. Tout retard de livraison dû à un cas de force majeure ou à un autre motif quelconque n'engagera aucune responsabilité de la part d'Alezia. On entend par force majeure toute circonstance indépendante de la volonté d'Alezia, rendant raisonnablement l’exécution du contrat impossible, y compris des retards dans la fourniture de biens ou de matériaux commandés à temps.</p>
  
      <p><strong>1.6.3. Livraison Partielle des Composants</strong><br>
      Si les biens sont livrés sans les périphériques qui leur sont normalement associés et si cette absence n’affecte pas la fonctionnalité essentielle des biens, ces derniers seront considérés comme ayant été intégralement livrés selon les termes de l'article.</p>
  
      <p><strong>1.6.4. Suspension du Délai de Livraison</strong><br>
      Si l'acheteur manque à l’une de ses obligations en temps utile, le délai de livraison sera suspendu sans qu'Alezia ne soit redevable d'une quelconque indemnisation.</p>
  
      <h3>Article 1.7. Prix</h3>
      <p><strong>1.7.1. Structure des Prix</strong><br>
      Sauf mention contraire explicite dans la confirmation de commande, les prix indiqués s’entendent hors frais d’emballage, et les biens sont considérés comme départ usine.</p>
  
      <p><strong>1.7.2. Modification des Coûts</strong><br>
      Les prix mentionnés dans les offres et contrats sont déterminés sur la base des taux de change, tarifs de transport, droits de douane, etc., en vigueur au moment de l’offre. Toute modification de ces éléments entraînera une révision des prix qui sera à la charge de l'acheteur.</p>
  
      <p><strong>1.7.3. Responsabilité de l'Expédition</strong><br>
      L’acheteur prend à sa charge toutes les opérations relatives à l’expédition, qu’il s’agisse du transport, des assurances ou des formalités douanières. Il est également tenu de vérifier la conformité des marchandises livrées et d'adresser toute réclamation au transporteur.</p>
  
      <h3>Article 1.8. Paiement</h3>
      <p><strong>1.8.1. Conditions de Paiement</strong><br>
      Sauf stipulation contraire dans la confirmation de commande, les paiements doivent être effectués comptant à la livraison, sans aucune réduction ni escompte.</p>
  
      <p><strong>1.8.2. Échéancier de Paiement</strong><br>
      Sauf convention contraire, le paiement des biens ou services vendus sera effectué comme suit : <br>
      • 50 % du montant convenu à la conclusion du contrat ; <br>
      • 50 % à la fin de l’installation.</p>
  
      <p><strong>1.8.3. Invariabilité des Délais de Paiement</strong><br>
      L’acceptation d’un délai de paiement ou de lettres de change n’entraîne pas novation et n’affecte en rien l'exigibilité de la dette, des intérêts, ou des indemnités applicables.</p>
  
      <p><strong>1.8.4. Suspension de Paiement</strong><br>
      Les réclamations concernant des biens livrés ne justifient en aucun cas une suspension de paiement.</p>
  
      <p><strong>1.8.5. Intérêts et Indemnités en Cas de Retard</strong><br>
      En cas de retard de paiement, après l’envoi d’un premier rappel, le client est redevable : <br>
      • D’intérêts calculés conformément à la loi du 4 mai 2023, au taux directeur majoré de 8 points ; <br>
      • D'une indemnité forfaitaire sur la base de l’encours : <br>
      o Pour un montant inférieur ou égal à 150 € : 20 € ; <br>
      o Pour un montant entre 150,01 € et 500 € : 30 € plus 10 % du montant sur la tranche ; <br>
      o Pour un montant supérieur à 500 € : 65 € plus 5 % de la somme restante, avec un maximum de 2 000 €. <br>
      Des indemnités identiques seront dues par Alezia en cas de manquement à ses obligations contractuelles.</p>
  
      <p><strong>1.8.6. Indemnité en Cas d’Annulation</strong><br>
      En cas d'annulation du marché, l'acheteur devra verser une indemnité forfaitaire équivalente à 30 % du montant total du contrat.</p>
  
      <h3>Article 1.9. Réserve de Propriété</h3>
      <p><strong>1.9.1. Propriété des Biens</strong><br>
      Alezia reste propriétaire des biens fournis jusqu’à ce que le paiement complet, y compris frais et intérêts, soit effectué. Le risque repose cependant sur l'acheteur dès la livraison.</p>
  
      <p><strong>1.9.2. Restrictions sur la Propriété</strong><br>
      L'acheteur ne peut ni céder les biens à des tiers, ni les donner en gage, ni les affecter de quelconque manière avant que le paiement intégral n'ait été effectué.</p>
  
      <p><strong>1.9.3. Droit de Reprise</strong><br>
      En cas de manquement de l'acheteur à ses obligations, Alezia est en droit de reprendre les biens vendus sans mise en demeure préalable. Cela entraîne également la résiliation automatique du contrat, sans préjudice des dommages-intérêts que pourrait réclamer Alezia.</p>
  
      <h3>Article 2.1.0 Conditions Générales du Service d’Entretien (Infrastructure Voix)</h3>
      <p><strong>2.1.1. Généralités</strong><br>
      L’acceptation par l'acheteur de nos offres, confirmations de commande et factures implique l’approbation de ces conditions générales pour le service d’entretien de l'infrastructure voix fourni par Alezia. Sauf indication contraire spécifiquement notée dans la confirmation de commande, ces conditions annulent et remplacent toute autre condition de service d’entretien qui pourrait être proposée par l’acheteur. Toute dérogation à ces conditions ne lie Alezia que si elle a été expressément acceptée et approuvée par écrit par Alezia.</p>
  
      <h3>Article 2.2. Objet</h3>
      <p><strong>2.2.1. Alezia s'engage à fournir tous les services nécessaires à l'entretien de l'infrastructure voix du matériel, qu’il inclue ou non des postes de travail, comme décrit dans le bon de commande.</strong></p>
  
      <h3>Article 2.3. Définitions</h3>
      <p><strong>2.3.1. Système de Base</strong><br>
      Le système de base comprend les processeurs (CPU) et équipements responsables des flux de trafic nécessaires au fonctionnement minimal de l'installation, à l'exclusion des équipements périphériques.</p>
  
      <p><strong>2.3.2. Système d’Exploitation</strong><br>
      Le système d’exploitation désigne le logiciel qui contrôle l’interaction entre les applications, le(s) processeur(s), et leurs périphériques. Il gère également l’allocation des ressources, les priorités, ainsi que la gestion des entrées/sorties et des données.</p>
  
      <p><strong>2.3.3. Protocole de Communication</strong><br>
      Un protocole de communication est un ensemble de règles normalisées définissant la manière dont les éléments du réseau échangent des informations.</p>
  
      <p><strong>2.3.4. Équipements Périphériques</strong><br>
      Les équipements périphériques sont toutes les applications ou serveurs connectés au système de base, qu'ils soient intégrés ou non, permettant à l'acheteur d'étendre les fonctionnalités de son installation.</p>
  
      <p><strong>2.3.5. Postes</strong><br>
      Les "postes fixes" désignent tous les équipements physiquement connectés au système de base, à l'exception des CPU prenant en charge les logiciels pour postes, tandis que les "postes mobiles" se réfèrent aux appareils portables.</p>
  
      <p><strong>2.3.6. Serveurs</strong><br>
      Les serveurs désignent des éléments d'un réseau qui incluent le matériel et les logiciels d’applications utilisés pour fournir un service spécifique.</p>
  
      <p><strong>2.3.7. Câblage</strong><br>
      Le câblage comprend les supports physiques tels que les paires de cuivre, les câbles coaxiaux ou la fibre optique, ainsi que les panneaux de raccordement, à l'exclusion des liaisons aériennes utilisant des faisceaux optiques ou hertziens. Le câblage se subdivise en quatre niveaux : <br>
      • Primaire : Liaisons entre bâtiments au sein d'une même entité, à l'exclusion des segments gérés par un opérateur public. <br>
      • Secondaire : Liaisons verticales entre étages d’un bâtiment. <br>
      • Tertiaire : Liaisons horizontales sur un même étage. <br>
      • Quaternaire : Points de raccordement de l’utilisateur.</p>
  
      <p><strong>2.3.8. Réseau</strong><br>
      Le réseau comprend tous les composants associés au câblage, permettant le transport de l’information entre les systèmes, périphériques et postes, à l'exception du système de base.</p>
  
      <p><strong>2.3.9. Pannes</strong><br>
      Les pannes désignent les perturbations affectant le fonctionnement de l'installation et qui sont intrinsèques à cette installation.</p>
  
      <p><strong>2.3.10. Service Level Agreement (SLA)</strong><br>
      Le SLA est une description d'un ensemble de niveaux de services, définis et mesurables, et est décrit à l'article 2.13 des présentes conditions générales.</p>
  
      <h3>Article 2.4. Modifications de l’Installation</h3>
      <p><strong>2.4.1. Définition des Modifications</strong><br>
      Les modifications de l'installation incluent : <br>
      • Toute extension ou réduction de capacité, <br>
      • Tout déplacement ou déménagement, <br>
      • Tout renouvellement partiel ou changement quelconque.</p>
  
      <p><strong>2.4.2. Adaptation des Redevances</strong><br>
      Les redevances seront adaptées en conséquence et seront applicables dès le mois suivant la modification.</p>
  
      <h3>Article 2.5. Durée du Service d’Entretien (Infrastructure Voix)</h3>
      <p><strong>2.5.1. Entrée en Vigueur</strong><br>
      Le service d’entretien prend effet le jour de la signature du bon de commande, ou, pour une nouvelle installation, à la date de mise en service de l'installation.</p>
  
      <p><strong>2.5.2. Durée Initiale et Reconduction</strong><br>
      Ce service est conclu pour l’année en cours et les trois années civiles suivantes. Il est renouvelable tacitement d’année en année.</p>
  
      <h3>Article 2.6. Redevance du Service d’Entretien</h3>
      <p><strong>2.6.1. Facturation des Redevances</strong><br>
      Les redevances seront facturées par anticipation pour la période de l’année en cours, puis pour chaque année civile suivante. Les factures sont payables dès leur réception.</p>
  
      <p><strong>2.6.2. Adaptation des Montants des Redevances</strong><br>
      Lors de chaque facturation, les montants des redevances fixés dans le bon de commande seront ajustés selon la formule suivante : <br>
      Pn = Po × (0,8 × Sn / So + 0,2) <br>
      Où : <br>
      • Po est la redevance initiale, <br>
      • Pn est la redevance adaptée, <br>
      • So représente les salaires + charges sociales sur lesquels est basée la redevance initiale, <br>
      • Sn représente les salaires + charges sociales en vigueur au moment de l'adaptation.</p>
  
      <p><strong>2.6.3. Prestations Hors Contrat</strong><br>
      Toute prestation non couverte par les présentes conditions fera l'objet d'une facturation distincte, payable dès réception.</p>
  
      <p><strong>2.6.4. Suspension des Prestations</strong><br>
      Le non-paiement à échéance entraîne la mise en demeure de l’acheteur et l'arrêt de toute prestation d’entretien jusqu’au paiement des sommes dues.</p>
  
      <h3>Article 2.7. Charges à la Charge de l’Acheteur</h3>
      <p><strong>2.7.1. Causes Extérieures</strong><br>
      Les frais pour la remise en état des équipements endommagés par des causes extérieures (telles que vibrations, humidité excessive, défaillances du réseau électrique, grèves, conflits de travail, etc.) sont à la charge de l'acheteur.</p>
  
      <p><strong>2.7.2. Biens Consommables</strong><br>
      Les biens consommables tels que papier, encre, rubans, supports magnétiques, et batteries sont à la charge de l'acheteur.</p>
  
      <p><strong>2.7.3. Appareils de Climatisation</strong><br>
      Les frais liés aux appareils de climatisation nécessaires sont à la charge de l’acheteur.</p>
  
      <p><strong>2.7.4. Taxes et Redevances</strong><br>
      Les redevances dues aux opérateurs de réseaux publics et les taxes diverses (par exemple, la consommation de courant électrique) sont à la charge de l'acheteur.</p>
  
      <p><strong>2.7.5. Prestations Spécifiques</strong><br>
      Les prestations effectuées en dehors des heures normales de travail ou celles nécessaires pour la remise en état d'une installation après une modification non réalisée par Alezia seront facturées séparément à l’acheteur.</p>
  
      <h3>Article 2.8. Frais d’Expédition</h3>
      <p>Les frais d'expédition des matériels envoyés en réparation à Alezia sont à la charge de l'acheteur. Les frais résultant de dommages ou de pertes pendant le transport sont également facturés.</p>
  
      <h3>Article 2.9. Conditions Complémentaires</h3>
      <p><strong>2.9.1. Pannes et Suspension du Service</strong><br>
      Aucune panne ou dérangement n’autorise l’acheteur à suspendre ou résilier le service d’entretien de l’infrastructure voix. Les droits d’Alezia ne peuvent être affectés si l’usage de l’installation ou d’une partie de celle-ci devient impossible ou inutile pour l’acheteur.</p>
  
      <p><strong>2.9.2. Accès à l’Installation</strong><br>
      L’acheteur garantit à Alezia un accès permanent à l’installation, selon les horaires définis dans le SLA. En outre, l’acheteur s’engage à fournir tous les moyens nécessaires pour accéder à toutes les parties de l’installation.</p>
  
      <p><strong>2.9.3. Transfert des Droits et Obligations</strong><br>
      Alezia se réserve le droit de transférer à un tiers ses droits et obligations relatifs au service d’entretien de l’infrastructure voix.</p>
  
      <p><strong>2.9.4. Respect des Obligations</strong><br>
      Alezia assume ses obligations au titre du service d’entretien de l’infrastructure voix uniquement lorsque les interventions sont effectuées par ses équipes ou sous son contrôle direct.</p>
  
      <p><strong>2.9.5. Propriété de l’Installation</strong><br>
      L’acheteur déclare être le propriétaire unique de l’installation ou, dans le cas contraire, être habilité par le propriétaire ou la copropriété à souscrire au service d’entretien de l’infrastructure voix.</p>
  
      <p><strong>2.9.6. Propriété des Composants Remplacés</strong><br>
      Alezia devient propriétaire de tout composant ou équipement repris lors d’un échange dans le cadre du service d’entretien de l’infrastructure voix.</p>
  
      <h3>Article 2.10. Responsabilités</h3>
      <p><strong>2.10.1. Absence de Garantie de Fonctionnement Continu</strong><br>
      Les prestations fournies dans le cadre du service d’entretien ne constituent pas une garantie que l’installation fonctionnera de manière ininterrompue.</p>
  
      <p><strong>2.10.2. Limitation de Responsabilité</strong><br>
      En aucun cas, Alezia ne peut être tenue responsable de pertes financières, telles que celles résultant d’un dysfonctionnement général de l’installation, de systèmes d'enregistrement des coûts, ou d’autres équipements périphériques, serveurs ou éléments d’un réseau de données, ni de leur mauvaise utilisation.</p>
  
      <p><strong>2.10.3. Qualité des Communications</strong><br>
      En cas de connexion directe ou indirecte à un opérateur de télécommunications via des interfaces même standardisées, Alezia ne pourra être tenue responsable de la qualité des communications ou des services. En cas de dégradation de la qualité, aucune compensation ne sera accordée.</p>
  
      <p><strong>2.10.4. Réseau et Câblage de l’Acheteur</strong><br>
      Si l'installation dépend du réseau ou du câblage de l'acheteur, celui-ci garantit la qualité et la capacité nécessaires au bon fonctionnement de l'installation.</p>
  
      <h3>Article 2.11. Résiliation du Service d’Entretien (Infrastructure Voix)</h3>
      <p><strong>2.11.1. Modalités de Résiliation</strong><br>
      L’acheteur et Alezia peuvent résilier le service d’entretien à l’expiration de la période en cours, moyennant un préavis de trois mois notifié par lettre recommandée.</p>
  
      <p><strong>2.11.2. Fin de Contrat</strong><br>
      Le service d’entretien prend fin de plein droit lors de la mise hors service définitive de l’installation.</p>
  
      <p><strong>2.11.3. Indemnité de Résiliation Anticipée</strong><br>
      En cas de mise hors service définitive de l’installation pendant la période initiale, l’acheteur devra payer une indemnité forfaitaire équivalente à 50 % des montants restant dus, avec un minimum équivalent à trois mois de service d’entretien.</p>
  
      <h3>Article 2.12. Résolution du Service d’Entretien</h3>
      <p><strong>2.12.1. Droits d’Alezia en Cas de Manquement</strong><br>
      Nonobstant tout recours judiciaire pour le recouvrement des sommes dues, Alezia se réserve le droit de prononcer unilatéralement la résolution du service d’entretien, aux torts de l’acheteur, par simple lettre recommandée, dans les cas suivants : <br>
      • Le non-paiement d'une somme due dans le cadre du service d’entretien, sept jours après l’envoi d’une mise en demeure. <br>
      • Le non-respect des conditions générales du service d’entretien. <br>
      • L’acheteur est déclaré en cessation de paiement, en banqueroute, en faillite ou en déconfiture.</p>
  
      <p><strong>2.12.2. Indemnité en Cas de Résolution</strong><br>
      Dans les cas cités, l'acheteur est tenu de payer une indemnité forfaitaire égale aux sommes dues pour la période en cours jusqu'à la prochaine échéance du service d’entretien, sans que cela puisse être inférieur au total de trois mois ni supérieur à 24 mois de service d’entretien.</p>
  
      <h3>Article 2.13. SLA du Service d’Entretien (Infrastructure Voix)</h3>
      <p><strong>2.13.1. Objet</strong><br>
      Le service d’entretien comprend le diagnostic, la localisation, et la correction des pannes de l'installation ainsi que le remplacement des éléments défectueux. L’entretien peut se faire soit à distance (par exemple via un modem), soit directement sur le site de l'acheteur.</p>
  
      <h3>Article 2.14. Définition des Pannes</h3>
      <p><strong>2.14.1. Pannes Graves</strong><br>
      Les pannes graves affectent sérieusement le fonctionnement de l'installation, telles que : <br>
      • Une panne conjointe de plus de 10 % des extensions, <br>
      • Une panne d’un poste opérateur, <br>
      • Une panne affectant plus de 20 % des connexions vers les opérateurs publics ou privés, à condition que la panne soit due à l'installation.</p>
  
      <p><strong>2.14.2. Pannes Mineures</strong><br>
      Les pannes mineures regroupent toutes celles qui ne peuvent être classées parmi les pannes graves, pour autant qu'elles soient liées à l'installation.</p>
  
      <h3>Article 2.15. Prestations du Service d’Entretien</h3>
      <p><strong>2.15.1. Heures d’Intervention</strong><br>
      Le service d’entretien est assuré pendant les heures normales de travail, les jours ouvrés de 9h00 à 12h30 et de 13h30 à 17h30.</p>
  
      <p><strong>2.15.2. Demande d'Intervention</strong><br>
      Les demandes d'intervention doivent être adressées par e-mail à l'adresse de support fournie par Alezia ou par téléphone au numéro indiqué dans le contrat.</p>
  
      <p><strong>2.15.3. Engagement d’Alezia</strong><br>
      Alezia s'engage à mettre en œuvre tous les moyens nécessaires pour effectuer les travaux visant à lever les pannes survenues dans le cadre d'une utilisation normale et en "bon père de famille" de l'installation.</p>
  
      <h3>Article 2.16. Délais d’Intervention</h3>
      <p>Après avoir été informée d'une panne durant ses heures de travail, Alezia s'engage à intervenir dans les délais standards suivants : <br>
      • En cas de pannes graves : intervention endéans les 4 heures de travail. <br>
      • En cas de pannes mineures : intervention endéans les 12 heures de travail.</p>
  
      <h3>Article 3.1. Droit Applicable et Litiges</h3>
      <p><strong>3.1.1. Droit Belge</strong><br>
      Tous les contrats conclus en vertu des présentes conditions générales sont régis par le droit belge.</p>
  
      <p><strong>3.1.2. Tribunaux Compétents</strong><br>
      En cas de litige relatif à l'interprétation ou à l'exécution du contrat, seuls les tribunaux de l'arrondissement de Dinant seront compétents pour statuer, conformément à la législation belge et aux pratiques commerciales locales.</p>
  
      <p><strong>3.1.3. Validité des Dispositions</strong><br>
      Si une disposition des présentes conditions devait être jugée nulle ou non applicable, cela n'affectera pas la validité des autres dispositions, et la clause invalide sera remplacée par une disposition autorisée par la loi, aussi proche que possible de l’intention initiale.</p>
    </div>
    <h3>Conditions Générales d’Utilisation des Services Cloud Alezia</h3>

<h4>1. Définitions</h4>
<ul>
  <li><strong>Services Alezia :</strong> Désignent les services hébergés dans le Cloud, fournis par Alezia (numéro d'entreprise : 1012.382.367), ayant son siège d’exploitation situé à Square des Martyrs 1, 6000 Charleroi.</li>
  <li><strong>Client Final :</strong> Fait référence à toute organisation qui utilise les services d’Alezia pour son propre usage. Passer commande auprès d’Alezia signifie que le client final accepte pleinement les présentes conditions d’utilisation.</li>
</ul>

<h4>2. Infrastructure</h4>
<p>L’infrastructure réseau et internet du client final est hors du champ de responsabilité d’Alezia. Toute mise en conformité nécessaire est à la charge du client final, qui doit l’adapter au fur et à mesure de l’évolution de ses besoins.</p>

<h4>3. Installation</h4>
<p>Les installations doivent être réalisées par un ingénieur mandaté par Alezia. Toute intervention hors de ce cadre sera facturée sur la base du temps passé et selon les tarifs en vigueur.</p>

<h4>4. Utilisation Illégale</h4>
<p>Le client final s’engage à ne pas utiliser les services d’Alezia à des fins illégales. En particulier, il est interdit d’utiliser des identifiants d’appel (caller ID) ne lui appartenant pas afin de se faire passer pour un autre appelant (technique connue sous le nom de "CLID spoofing").</p>

<h4>5. Sécurisation</h4>
<p>La sécurisation des services Cloud est essentielle et constitue une responsabilité partagée entre Alezia et ses clients finaux. La gestion des mots de passe est de la responsabilité du client final, qui doit également veiller à l’installation de dispositifs de sécurité comme un firewall sur son réseau.</p>

<h4>6. Garantie</h4>
<p>Alezia garantit le bon fonctionnement de ses services s’ils sont utilisés conformément aux prescriptions définies (infrastructure adéquate, bande passante suffisante, etc.) et avec des terminaux fournis ou approuvés par Alezia. La garantie des produits fournis est d’un an. Alezia et son partenaire Wildix s'efforcent de garantir la meilleure disponibilité des services grâce à une infrastructure robuste et un suivi constant (monitoring). Cependant, Alezia a une obligation de moyens et non de résultat, et aucune compensation ne sera accordée en cas de dégradation ou d’indisponibilité des services.</p>

<h4>7. Reconduction du Service Cloud</h4>
<p>À l'expiration de la durée initiale du contrat (1 ou 5 ans), celui-ci sera automatiquement renouvelé pour une durée équivalente à celle du contrat initial, à moins que l'une des parties n'informe l'autre par lettre recommandée au moins trois mois avant la fin de la durée initiale ou de la reconduction de sa décision de ne pas renouveler. Pour les contrats mensuels, tout mois entamé est dû.</p>

<h4>8. Résiliation et Fin de Contrat du Service Cloud (Hors Renting ou Leasing)</h4>
<p>Les abonnements peuvent être conclus pour différentes durées (un mois, douze mois, soixante mois), selon les termes du contrat. En cas de renting ou leasing, c'est le contrat spécifique de renting ou leasing qui est prioritaire : <br>
• <strong>Contrat Mensuel :</strong> Le client peut mettre fin à son contrat chaque mois, en respectant un préavis de cinq jours avant la fin du mois en cours. Tout mois entamé est dû.<br>
• <strong>Contrat Annuel :</strong> Le client peut résilier le contrat à chaque échéance annuelle, en notifiant son intention au moins un mois avant la fin de la période annuelle.<br>
• <strong>Contrat de Soixante Mois :</strong> Le client peut résilier le contrat à chaque échéance quinquennale, en notifiant son intention au moins trois mois avant la fin de la période de 60 mois.</p>

<p>La résiliation ou l’arrêt du contrat doit être notifié par e-mail à l’adresse info@alezia.be ou par lettre recommandée envoyée à Alezia, Square des Martyrs 1, 6000 Charleroi, dans les délais indiqués ci-dessus. En cas de résiliation anticipée par le client, le solde du contrat (avec les services inclus dans la dernière facture) sera facturé.</p>

<h4>9. Résiliation et Rachat du Contrat de l’Ancien Prestataire</h4>
<p>En accord avec le bon de commande, Alezia peut prendre en charge l’indemnité de rupture du contrat de l’ancien prestataire, à condition que cette prise en charge soit mentionnée dans le bon de commande. Dans ce cas, le client doit refacturer les frais à Alezia dans un délai maximal de six mois suivant la mise en service de la nouvelle installation.</p>

  </template>
  
  <script>
  export default {
    name: 'ConditionGeneral',
  }
  </script>
  
  <style scoped>
  .condition-general {
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  h1 {
    color: #333;
  }
  h2 {
    margin-top: 20px;
  }
  h3 {
    margin-top: 15px;
  }
  </style>
  